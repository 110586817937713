
import { 
    IonContent, 
    IonPage, 
    IonGrid, 
    IonRow, 
    IonCol, 
    IonText, 
    IonButton, 
    IonInput,

    onIonViewDidEnter,
  
  } from '@ionic/vue';
import { defineComponent, ref } from '@vue/runtime-core';
import * as yup from 'yup';
import { useForm, useField } from 'vee-validate';
import { isApiError } from "@/lib/api-endpoints";
import { useRouter } from 'vue-router';
import { useStore } from '@/store';
import t from '@/translation';

export default  defineComponent({
  name: 'Login',
  components: { 
    IonContent, 
    IonPage, 
    IonGrid, 
    IonRow, 
    IonCol, 
    IonText, 
    IonButton, 
    IonInput
    
  },
  setup(){
  
    const loginSchema = yup.object({
      email: yup.string().email().max(50).required().label(t("Email")),
      password: yup.string().min(6).max(64).required().label(t("Password")),
    });

    // Create a form context with the validation schema
    const { values, validate, errors: formErrors, resetForm, setErrors } = useForm({
      validationSchema: loginSchema,
    });

    const { errorMessage: emailError, value: emailValue} = useField ('email');
    const { errorMessage: passwordError, value: passwordValue} = useField ('password');

    const showErrors = ref(false);

    const router = useRouter();
    const store = useStore();

    onIonViewDidEnter(async () => {
      // NOTE: This portion of the code tries to load userData
      // from the server, and redirects if it can.  The router
      // will not route to this page if userData is already loaded
      // when the route is selected.
      const userData = await store.dispatch("getUserData", {force: true});
      if (userData && (! isApiError(userData))){
        // if we can get user data, there is no need to login.
        if (userData.wedding){
          router.push("/tabs/home");
        } else {
          router.push("register_wedding");
        }
      }
      setErrors({email:undefined, password:undefined});
      showErrors.value = false;
    });

    async function onLogin(){
      const result = await validate();
      showErrors.value = true;
      if (result.valid){

        const userData = await store.dispatch("login", values);
        if (! isApiError(userData)){
          if (userData.wedding){
            router.push("/tabs/home");
          } else {
            router.push("/register_wedding");
          }
          window.setTimeout(resetForm, 2000); // delayed so that validation errors are not seen before the page transistions.
        }
      }
    }

    return {
      values,
      validate,
      formErrors,
      onLogin,
      emailError,
      emailValue,
      passwordError,
      passwordValue,
      showErrors,
      store,
    };
  },
});
